.loader-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99999;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0
  }
  
  .loader {
    border: 4px solid #f8f7f7;
    border-radius: 50%;
    border-top: 4px solid #8b65fc;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 1.5s linear infinite;
    align-self: center;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }