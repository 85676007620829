.inputStyle {
  width: fit-content;
}

.outline {
  outline: none !important;
}
.table th,
.table td {
  vertical-align: middle !important;
}
.pointer {
  cursor: pointer;
}
.heading {
  position: fixed;
  transform: translate(50%);
  right: 50%;
  margin-top: 50px;
}
.notification-modal {
  max-height: 500px;
  max-width: 400px;
  overflow-y: scroll;
  background: #fff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.tools {
  position: relative;
  display: inline-block;
  bottom: 12px;
  /* border-bottom: 1px dotted black; */
}

.tools .tooltiptext1 {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tools:hover .tooltiptext1 {
  visibility: visible;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}